import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import MessageIcon from "../assets/images/icons/message-icon.svg";
import ChatPopup from "./chatpopup";

export default function ChatbotFloatingButton() {
  const [chatSuccess, setChatSuccess] = useState(false);
  const [clicks, setClicks] = useState(1);

  const openChat = (clicks) => {
    if ((clicks + 1) % 2 == 0) {
      setChatSuccess(true);
      setClicks(clicks + 1);
    } else {
      setChatSuccess(false);
      setClicks(clicks - 1);
    }
  };

  const closeChat = (e) => {
    setChatSuccess(false);
    setClicks(clicks - 1);
  };

  return (
    <div style={{zIndex:100, position:"absolute"}}>
      <Row className="msngr-div" onClick={() => openChat(clicks)} style={{cursor: "pointer"}}>
        <img src={MessageIcon} alt="image" />
        <Col className="ntfcn"></Col>
      </Row>

      {chatSuccess && <ChatPopup closeChat={closeChat} openChat={openChat} />}
    </div>
  );
}
