import { useState } from "react";
import { Auth } from "aws-amplify";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { tabTitle } from "../components/tabsTitle";
import { set } from "react-hook-form";

const SetPasswordPage = () => {
  tabTitle("Forgot Password");
  const navigate = useNavigate();
  const location = useLocation();
  const [payload, setpayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [verified , setVerified] = useState(false);

  const onChange = (e) => {
    setpayload({ ...payload, [e.target.name]: e.target.value });
  };

  const onClick = async () => {
    try {
      setLoading(true);
      const forgotPassword = await Auth.forgotPasswordSubmit(
        location.state.username,
        payload.code,
        payload.new_password
      );
      setLoading(false);
      if (forgotPassword) {
        navigate("/login");
      }
    } catch (e) {
      setLoading(false);
      alert(e.message);
      console.log("e: ", e);
    }
  };

  const handleVerify = async () => {
    // if (payload.code) {
      setVerified(true);
    // }
    // else {
      // alert("Please enter the code sent to your email");
    // }
  };

  const submitVerifyForm = (e) => {
    e.preventDefault();
    handleVerify();
  };

  return (
    <>
      <div className="login-page pb-5">
        {loading && (
          <div className="loader">
            <ClipLoader
              color={"#ffffff"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        <Container>
          <Row>
            <Col md={12}>
              <div className="forms">
                <h1 className="py-5 mt-0 mt-md-5 mb-3">Forgot password</h1>
                <Form className="login-form" onSubmit={submitVerifyForm}>
                  <Row className="form-inputs mx-auto py-4">
                    {(!verified)?<div><Col md={12}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="pt-2">
                          Please enter the code sent to your email
                        </Form.Label>
                        <Form.Control
                          name="code"
                          type="text"
                          placeholder="Your verification code"
                          onChange={onChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Button
                        variant="primary"
                        className="form-btn mt-2"
                        onClick={handleVerify}
                      >
                        Verify Code
                      </Button>
                    </Col>
                    </div>
                    :
                    <div>
                      <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="pt-2">
                            Your email address
                          </Form.Label>
                          <Form.Control
                            type="email"
                            value={location.state.username}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="pt-2">New password</Form.Label>
                          <Form.Control
                            name="new_password"
                            type="password"
                            placeholder="New password"
                            onChange={onChange}
                          />
                          <Form.Label className="pt-2">Confirm new password</Form.Label>
                          <Form.Control
                            name="confirm_new_password"
                            type="password"
                            placeholder="Confirm New password"
                            onChange={onChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                      <Button
                        variant="primary"
                        className="form-btn mt-2"
                        onClick={onClick}
                      >
                        Reset password
                      </Button>
                    </Col>
                    </div>
                    }
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SetPasswordPage;
