import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cards from "./cards";
import TrainerCard from "./trainerCard";
import GameUpLogo from "../assets/images/logo-icon.svg"

const levelToText = (level) => {
  if (level < 10) {
    return "Never Touched a Ball";
  } else if (level < 20) {
    return "Beginner";
  } else if (level < 30) {
    return "Intermediate";
  } else if (level < 40) {
    return "Advanced";
  } else if (level < 50) {
    return "Elite";
  } else if (level < 60) {
    return "All-Star";
  } else {
    return "Pro-Bound";
  }
};

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: true,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        centerMode: true,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        centerPadding: "30px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: true,
        centerMode: true,
        infinite: true,
        centerPadding: "30px",
        slidesToShow: 1,
      },
    },
  ],
};

export default function ResultsSlider(props) {
  return (
    <>
      <Slider {...settings} className="results-slider-outer">
        {props.cardsData.map((item, idx) => {
          return (
            <div className="slider-cards" key={idx}>
              {props.cardType === "trainer" ? (
                <TrainerCard
                  item={item}
                  cardTitle={`${item.firstName} ${item.lastName}`}
                  cardSubTitle={`${
                    item.city == null ? "New York" : item.city
                  }, ${item.state == null ? "NY" : item.state}`}
                  cardImage={item.profileImage}
                  city={
                    item.city == "" || item.city == null
                      ? item.city
                      : "New York"
                  }
                  website={item?.website}
                  cardType={item?.cardType}
                  state={item?.state}
                  email={item?.email}
                  company={item?.company}
                  relevantExperience={item?.relevantExperience}
                  reference={item?.reference}
                  socialMediaHandles={item?.socialMediaHandles}
                  resume={item?.resume}
                  phoneNumber={item?.phoneNumber}
                  calendly={item?.calendly}
                />
              ) : (
                <Cards
                  cardType="team"
                  item={item}
                  cardTitle={item.name}
                  cardSubTitle={`${
                    item.city == null ? "New York" : item.city
                  }, ${item.state == null ? "NY" : item.state}`}
                  cardImage={GameUpLogo}
                  minimumAge={item.minimumAge}
                  maximumAge={item.maximumAge}
                  city={
                    item.city == "" || item.city == null
                      ? item.city
                      : "New York"
                  }
                  state={item.state}
                  level={item.ranking}
                  gender={item.gender}
                />
              )}
            </div>
          );
        })}
      </Slider>
    </>
  );
}
