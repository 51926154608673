/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://a5o4dtgdzfhhdiwga4wvodj6ea.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-eo3btxlkvfbchlue4pgjhfawnm",
    "aws_cognito_identity_pool_id": "us-east-1:7ca24cbd-7387-40a2-805c-e32dcf0c15ff",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_njR8IlM7N",
    "aws_user_pools_web_client_id": "5a480bq59568inpra5nkqj8g2i",
    "oauth": {
        "domain": "gameupfrontend3bd3eacd-3bd3eacd-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://game-up.us/",
        "redirectSignOut": "https://game-up.us/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "gameupfrontendstorage100229-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
